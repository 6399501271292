import React, { useCallback, useContext, useEffect, useState } from "react";
import SEO from "@src/components/SEO";
import { BodyText, Title } from "@src/components/nessie-web";
import shipelin from "@src/assets/images/dojo-islands/play/shipeling-90.webp";
import dojoislandslogo from "@src/assets/images/dojo-islands/play/dojoislandslogo-90.webp";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import DojoIslandsOpengraphImage from "@src/assets/images/dojo-islands-opengraph.jpg";
import { Flex } from "@src/components/Boxes";
import { mediaQueries } from "@src/styles/theme";
import Translate from "@src/components/translation/Translate";
import DojoIslandsButton from "@src/components/DojoIslandsButton";
import { logEvent } from "@src/utils/logClient";
import { getRelativePath } from "@src/utils/routes";
import { AppDataContext } from "@src/components/AppDataContext";
import { DojoIslandsLoginModal } from "./DojoIslandsLoginModal";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { DojoIslandsLoginOneTimeCodeModal } from "./DojoIslandsLoginOneTimeCodeModal";
import { useDojoIslandsLoginWithEmail } from "./data";
import useSearchParams from "@src/utils/useSearchParams";
import { sanitizeCodeInput } from "@src/components/one-time-code/OTCInput";

const bigDeviceVersion = mediaQueries[0];

const DojoIslandsDisplay3 = styled(Title)`
  text-transform: uppercase;
  font-family: "Grandstander";
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0%;
  ${bigDeviceVersion} {
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -0.005em;
  }
`;

const DojoIslandsHeading3 = styled(BodyText)`
  text-transform: uppercase;
  font-family: "Grandstander";
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.005em;
  ${bigDeviceVersion} {
    font-size: 20px;
    line-height: 25px;
  }
`;

const ResponsiveFlex = styled(Flex)`
  flex-direction: column;
  .text {
    order: 1;
  }
  .shipeling {
    order: 0;
    height: 30%;
  }

  .shipeling img {
    height: 140%;
    margin-top: -20px;
  }

  ${bigDeviceVersion} {
    flex-direction: row;
    .text {
      order: 0;
    }
    .shipeling {
      order: 1;
      height: 100%;
    }

    .shipeling img {
      height: 100%;
      margin-top: 0px;
    }
  }
`;

const ResponsiveDojoIslandsLogo = styled("img")`
  width: 222px;
  ${bigDeviceVersion} {
    width: 300px;
  }
`;

const ResponsiveDojoIslandsButton = styled(DojoIslandsButton)`
  width: 100%;

  ${bigDeviceVersion} {
    max-width: 250px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: center;

  padding-left: 20px;
  padding-right: 20px;

  gap: 14px;
  flex-direction: column;
  ${bigDeviceVersion} {
    flex-direction: row;
  }
`;

export const PlayDojoIslandsPage: React.FC = () => {
  const translation = useContext(TranslationContext);
  const {
    data: { loadingSession, userData, geolocation },
  } = useContext(AppDataContext);
  const hasSession = !!userData;
  const entityType = userData?.type;
  const country = geolocation?.countryCode;

  const email = useSearchParams("email")[0] || undefined;
  const emailVerificationHash = useSearchParams("hash")[0] || undefined;
  const rawOtc = useSearchParams("otc")[0] || undefined;
  const target = getOtcLoginTarget(useSearchParams("target")[0]);
  const source = useSearchParams("source")[0] || undefined;
  const otc = sanitizeCodeInput(rawOtc);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [parentEmail, setParentEmail] = useState(email);
  const [isLoginOneTimeCodeModalOpen, setLoginOneTimeCodeModalOpen] = useState(false);

  const { loginWithEmail } = useDojoIslandsLoginWithEmail();

  const onLearnMoreClick = useCallback(() => {
    logEvent({
      eventName: "web.external_page.dojo_islands_play.learn_more.tap",
      metadata: { hasSession, entityType, country, source },
    });
  }, [country, entityType, hasSession, source]);

  const onGetStartedClick = useCallback(() => {
    logEvent({
      eventName: "web.external_page.dojo_islands_play.get_started.tap",
      metadata: { hasSession, entityType, country, source },
    });
    setParentEmail("");
    setLoginModalOpen(true);
  }, [country, entityType, hasSession, source]);

  const onLoginCodeSent = useCallback((parentEmail: string) => {
    setParentEmail(parentEmail);
    setLoginOneTimeCodeModalOpen(true);
    setLoginModalOpen(false);
  }, []);

  const onResendCode = useCallback(async () => {
    return await loginWithEmail(parentEmail);
  }, [loginWithEmail, parentEmail]);

  useEffect(() => {
    if (email && otc) {
      onLoginCodeSent(email);
    }
  }, [email, otc, onLoginCodeSent]);

  const t = useContext(TranslationContext);
  const extendedDescription = t.translateIfAble("page_descriptions.dojo_islands_play_extended") as string;

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/Grandstander/Grandstander.css"></link>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/OmoType/omoType.css"></link>
      </Helmet>
      <SEO
        title="page_titles.dojo_islands_play"
        description="page_descriptions.dojo_islands_play"
        image={DojoIslandsOpengraphImage}
      />
      <Helmet>
        {/* override base SEO page description with a longer one for page indexing */}
        <meta name="description" content={extendedDescription}></meta>
      </Helmet>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          background: "linear-gradient(65deg, #7AD9FF 23.45%, rgba(82, 183, 255, 0.00) 100%), #00A3FF",
        }}
      />
      <ResponsiveFlex dir="row" gap={24} style={{ position: "relative", width: "100%", height: "max(100vh,600px)" }}>
        <div
          className="text"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            width: "100%",
            height: "100%",
            flex: "1",
            padding: "0px 12px 50px 12px",
            zIndex: 2,
          }}
        >
          <ResponsiveDojoIslandsLogo src={dojoislandslogo} alt="dojo islands logo" />
          <div
            style={{
              maxWidth: "485px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: "18px",
            }}
          >
            <DojoIslandsDisplay3>
              <Translate path="pages.dojo_islands_play.title" />
            </DojoIslandsDisplay3>
            <DojoIslandsHeading3>
              <Translate path="pages.dojo_islands_play.subtitle" />
            </DojoIslandsHeading3>
          </div>
          <ButtonWrapper>
            <ResponsiveDojoIslandsButton primary onClick={onGetStartedClick}>
              <Translate path="pages.dojo_islands_play.get_started" />
            </ResponsiveDojoIslandsButton>
            <ResponsiveDojoIslandsButton
              secondary
              as="a"
              href={getRelativePath("/dojo-islands/")}
              onClick={onLearnMoreClick}
              aria-label={translation.translate("pages.dojo_islands_play.learn_more_link_description").toString()}
            >
              <Translate path="pages.dojo_islands_play.learn_more" />
            </ResponsiveDojoIslandsButton>
          </ButtonWrapper>
        </div>
        <div
          className="shipeling"
          style={{ flex: "1", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "start" }}
        >
          <img src={shipelin} alt="Dojo Islands Shippelin" />
        </div>
      </ResponsiveFlex>

      {isLoginModalOpen && (
        <DojoIslandsLoginModal
          onClose={() => setLoginModalOpen(false)}
          onSent={onLoginCodeSent}
        ></DojoIslandsLoginModal>
      )}

      {isLoginOneTimeCodeModalOpen && (
        <DojoIslandsLoginOneTimeCodeModal
          onClose={() => setLoginOneTimeCodeModalOpen(false)}
          resendCode={onResendCode}
          parentEmail={parentEmail}
          initialCode={parentEmail && otc ? otc : ""}
          target={target}
          source={source}
          emailVerificationHash={emailVerificationHash}
        ></DojoIslandsLoginOneTimeCodeModal>
      )}
    </>
  );
};

function getOtcLoginTarget(target: string | undefined) {
  if (target === "parentConsent") {
    return target;
  }

  return "player_selection";
}
