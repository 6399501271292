import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { DojoIslandsModal } from "./DojoIslandsModal";
import modalLetter from "@src/assets/images/dojo-islands/play/modal_letter-90.webp";
import { Title, theme } from "@src/components/nessie-web";
import { mediaQueries } from "@src/styles/theme";
import Translate from "@src/components/translation/Translate";
import { Column, Row } from "@src/components/extended";
import { isDev } from "@src/utils/env";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { logEvent } from "@src/utils/logClient";
import { DojoLoginWithEmailResult, includes, navigateToAction } from "./data";
import { OTCInput } from "@src/components/one-time-code/OTCInput";
import { callApi } from "@src/utils/api-types/callApi";

const TIMEOUT_SECONDS = isDev() ? 5 : 30;

const bigDeviceVersion = mediaQueries[0];

const Header = styled(Title)`
  text-transform: uppercase;
  font-family: "Grandstander";
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: normal;
  font-feature-settings: "liga" off;
  text-align: center;
  color: ${theme.colors.contentPrimary};
  ${bigDeviceVersion} {
    font-size: 35px;
    line-height: 42px;
    letter-spacing: -0.005em;
  }
`;

const SubHeader = styled(Title)`
  font-family: "OmoTypeCBlack";
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${theme.colors.contentSecondary};
  ${bigDeviceVersion} {
    font-size: 18px;
  }
`;

const ErrorMessage = styled(Title)`
  font-family: "OmoTypeCBlack";
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${theme.colors.contentDanger};
`;

const FooterButtonText = styled.span<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? theme.colors.taro40 : theme.colors.midnight)};
  text-align: center;
  font-feature-settings: "liga" off;

  font-family: "Grandstander";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.09px;
  text-transform: uppercase;
`;

const ResponsiveDojoIslandsLogo = styled("img")`
  height: 80px;
`;

const UnstyledButton = styled.button<{ disabled: boolean }>`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: auto;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

function useCountdown(seconds: number) {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 0) {
        return;
      }

      setTime(time - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return { time, reset: setTime };
}

export const DojoIslandsLoginOneTimeCodeModal = ({
  onClose,
  resendCode,
  parentEmail,
  initialCode,
  target,
  source,
  emailVerificationHash,
}: {
  onClose: () => void;
  resendCode: () => Promise<DojoLoginWithEmailResult>;
  parentEmail: string;
  initialCode?: string;
  target: "player_selection" | "parentConsent";
  source?: string;
  emailVerificationHash?: string;
}) => {
  const t = useContext(TranslationContext);
  const [code, setCode] = useState(initialCode);
  const [errorMessage, setErrorMessage] = useState("");
  const { time, reset } = useCountdown(TIMEOUT_SECONDS);
  const verificationInputRef = useRef<HTMLInputElement>(null);

  const isConsentLink = target === "parentConsent";

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.dojo_islands_play.otc.page_view",
      metadata: { source, target },
    });
  }, [source, target]);

  const onChange = useCallback((value: string) => {
    setErrorMessage("");
    setCode(value);
  }, []);

  const onComplete = useCallback(
    (value: string) => {
      if (value.length !== 6) {
        return;
      }

      callApi({
        path: "/api/session/child-code-login",
        method: "post",
        body: {
          login: parentEmail,
          code: value,
        },
      })
        .then((result) => {
          logEvent({
            eventName: "web.external_page.dojo_islands_play.otc.submit.result",
            eventValue: "success",
            metadata: { source, target },
          });
          navigateToAction(target, source, emailVerificationHash);
        })
        .catch((err) => {
          logEvent({
            eventName: "web.external_page.dojo_islands_play.otc.submit.result",
            eventValue: "error",
            metadata: { source, target },
          });
          setErrorMessage(
            t
              .translate(
                isConsentLink
                  ? "pages.dojo_islands_play.one_time_code_modal.invalid_consent_link"
                  : "pages.dojo_islands_play.one_time_code_modal.incorrect_code",
                { parent_email: parentEmail },
              )
              .toString() || "Something went wrong",
          );
        });
    },
    [parentEmail, source, target, emailVerificationHash, t, isConsentLink],
  );

  const onResend = useCallback(async () => {
    const result: DojoLoginWithEmailResult | null = await resendCode().catch((err) => null);

    logEvent({
      eventName: "web.external_page.dojo_islands_play.otc.resend_code",
      metadata: { source, target },
    });

    if (includes(["waitlist", "disabled"] as const, result)) {
      // if the user is not eligible to get a OTC it means he/she got here
      // by hacking the URL. Redirect to dojoislands.com
      if (window) {
        window.location.replace("https://dojoislands.com");
      }
    }

    setCode("");
    if (!isConsentLink) {
      // only clear the message if it is not the consent link error message
      setErrorMessage("");
      verificationInputRef.current?.focus();
    }

    reset(TIMEOUT_SECONDS);
  }, [resendCode, source, target, isConsentLink, reset]);

  // Workaround for t dependency in onComplete re-executing the hook multiple times
  // as t is changing on every render
  const onCompleteRef = useRef(onComplete);
  onCompleteRef.current = onComplete;
  useEffect(() => {
    if (initialCode) {
      onCompleteRef.current(initialCode);
      reset(0);
    }
  }, [initialCode, reset]);

  return (
    <DojoIslandsModal labelledby="modal-heading" closeModal={onClose}>
      <Column alignItems="stretch" gap={[18]}>
        <Row justifyContent="center">
          <ResponsiveDojoIslandsLogo src={modalLetter} alt="dojo islands logo" />
        </Row>

        {isConsentLink ? (
          <Column gap={[24, 30]}>
            <Column alignItems="center" gap={12}>
              <Header id="modal-heading">
                <Translate path="pages.dojo_islands_play.one_time_code_modal.title" />
              </Header>
            </Column>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <UnstyledButton disabled={time > 0} onClick={onResend}>
              <FooterButtonText disabled={time > 0}>
                <Translate path="pages.dojo_islands_play.one_time_code_modal.resend_consent_link" />
                {time > 0 ? ` (${time})` : ""}
              </FooterButtonText>
            </UnstyledButton>
          </Column>
        ) : (
          <Column gap={[24, 30]}>
            <Column alignItems="center" gap={12}>
              <Header id="modal-heading">
                <Translate path="pages.dojo_islands_play.one_time_code_modal.title" />
              </Header>

              <SubHeader>
                <Translate
                  path="pages.dojo_islands_play.one_time_code_modal.subtitle"
                  subs={{ parent_email: parentEmail }}
                />
              </SubHeader>
            </Column>

            <OTCInput
              ref={verificationInputRef}
              value={code}
              onChange={onChange}
              onComplete={onComplete}
              length={6}
              showError={!!errorMessage}
              autoFocus
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <UnstyledButton disabled={time > 0} onClick={onResend}>
              <FooterButtonText disabled={time > 0}>
                <Translate path="pages.dojo_islands_play.one_time_code_modal.resend" />
                {time > 0 ? ` (${time})` : ""}
              </FooterButtonText>
            </UnstyledButton>
          </Column>
        )}
      </Column>
    </DojoIslandsModal>
  );
};
